.home {
  width: 100%;
  margin: 0 auto;
  background: #fff !important;
}
.root {
  background: #f2f3f5;
  width: 100%;
  height: 100%;
}
.sub-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.article-thumb {
  background-position: center;
  background-size: cover;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 10px;
}
.article-thumb-small {
  width: 100%;
  height: 70%;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}
.article-thumb-xsmall {
  width: 100%;
  height: 50%;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}
.article {
  padding: 6px;
  background: #fff;
  border-radius: 12px;
}
.article:hover {
  box-shadow: 0 1px 4px 0 rgba(2, 98, 98, 0.4);
  cursor: pointer;
}
.article-description {
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
.article-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.article-footer {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: space-between;
  color: 1px solid #eeeeee;
}
.list-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question {
  background: #fff;
  padding: 10px;
  border: 2px solid #40a9ff;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.question-text {
  font-size: 13px;
  font-weight: bold;
  color: #40a9ff;
}
.spinner {
  display: flex;
  margin-top: 150px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
}

.feature-root {
  background: #192534;
}
.feature {
  margin-top: 10;
  border-radius: 20px;
}
.feature-item {
  box-shadow: 0 1px 4px 0 rgba(102, 98, 98, 0.4);
  position: relative;
  width: 100%;
}
.feature-list {
  padding: 5px;
  background: #192534;
}
/* @media screen and (max-width: 1024px) {
 .feature-item-image {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 240px;
}
}
@media screen and (max-width: 768px) {
 .feature-item-image {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 175px;
}
}
@media screen and (min-width: 1025px) {
 .feature-item-image {
  display: block;
  max-width: 100%;
  height: auto;
}
} */
figure.feature-thumb {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 47.36%;
  position: relative;
}
figure.feature-thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.feature-item-image {
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: rgba(#f00, 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
}

.temp-thumb {
  opacity: 0;
}

.feature-item-image-lg {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 350px;
  height: 350px;
}
.tempThumb {
  opacity: 0;
}
.mainThumb {
  background-position: center;
  background-size: cover;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background-image: url(https://imgproxy3.cdnforo.com/4ZzThTobbqx7PLjeP-hg94TNp_SQ3va_jPm9Sb3u184/h…tinhte.vn/data/attachment-files/2020/02/4898776_cover_messenger_tinhte.jpg);
  height: 0;
  padding-top: 61.9%;
}

/*Custom CSS - Overide*/
.video {
  border-radius: 12px 12px 0 0;
  background: #fff;
}
.video-list {
  padding: 5px;
  background: #192534;
}
.video-control {
  font-size: 26px;
}
.video-control-loading {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.hot-topic {
  padding: 5px;
  border-top: 3px solid #007bff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 4px 0 rgba(102, 98, 98, 0.4);
  background: #fff;
}
.hot-topic-title {
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}
.hot-topic-item {
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hot-topic-info {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.hot-topic-info:hover {
  color: #007bff;
  cursor: pointer;
}
.hot-topic-thumb {
  width: 100%;
  height: 100%;
  background-position: 0 0 !important;
  border-radius: 10px;
}

.button-load {
  margin: 0px auto;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.title-post:hover {
  color: #007bff;
}

.category {
  color: #6c757d;
}
.category:hover {
  color: #007bff;
}
.video-item-title {
  font-size: 20px;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  max-height: 4.8em;
  overflow: hidden;
  color: white;
  text-align: left;
}
.video-item-detail {
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  max-height: 4.8em;
  overflow: hidden;
  color: #ababab;
  text-align: left;
}
.video-item-thumb {
  width: 90%;
  border-radius: 10px;
  margin: 7px 0 7px 10px;
}
.list-video {
  padding: 5px;
  background-color: #1a1a1a;
}
.video-item:hover {
  background-color: #404040;
}
.video-item {
  border-radius: 10px;
  cursor: pointer;
  width: 97%;
}
.ant-card-body h6 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*SWIPER*/
.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.slick-prev:before,
.slick-next:before {
  color: #1a1a1a !important;
}

.img-169 {
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.image-wrapper {
  display: block;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}
.image-wrapper .image {
  display: block;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}

/* Position child elements relative to this element */
.aspect-ratio-box {
  position: relative;
}
.crop {
  width: 100%;

  overflow: hidden;

  position: relative;
}

.crop::before {
  content: '';

  display: block;

  padding-top: 56%;
}

.crop img {
  display: block;

  width: 100%;

  height: auto;

  position: absolute;

  top: 0;

  left: 0;
}

.pa-image-16-9 .et_pb_image_wrap {
  padding-top: 56.25%;
  display: block;
}

.pa-image-16-9 .et_pb_image_wrap img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
